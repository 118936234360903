export const FirebaseConfig = {
	"projectId": "cow-india",
	"appId": "1:390395728165:web:bb0f6bc36fde5564e24acc",
	"databaseURL": "https://cow-india-default-rtdb.firebaseio.com",
	"storageBucket": "cow-india.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyBN1XNAW04158LUNyVxQ77NGSmyCmGUy8s",
	"authDomain": "cow-india.firebaseapp.com",
	"messagingSenderId": "390395728165",
	"measurementId": "G-QKHPJ8MDEF"
};